<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>数据排行榜</a-breadcrumb-item>
            <a-breadcrumb-item>新增线索排行</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-tooltip placement="topLeft" >
                        <template slot="title">
                        <span>上课校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                    </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-tooltip placement="topLeft" >
                        <template slot="title">
                        <span>课程科目</span>
                        </template>
                        <a-select allowClear placeholder="请选择课程科目" :filterOption="filterOption" @change="handleSubjectChange" style="width: 180px">
                        <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                    </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group">
                
                <statistics :count='counts' :col="6"/> 

                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="12">
                      <LRanking title="员工线索新增排行" :rank_data="leadsData" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                    <LRanking title="校区线索新增排行" :rank_data="studioLeadsData" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import ranges from "@/common/mixins/ranges"
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'clue',
  components:{
    statistics
  },
  data() {
    return {
      loading:false,
      leadsData:{},
      studioLeadsData:{},
      counts:[],
      studios:[],
      classCategorys:[],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        studio_id:'',
        course_cate_id:'',
      },
    }
  },
  mixins: [ranges],
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStudio();
    this.getClassCategory();
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
      let res = await this.$store.dispatch('analysisRankingLeadsAction', obj)
      this.leadsData = res.data.leads
      this.studioLeadsData = res.data.studio_leads
      this.loading = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.classCategorys = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleSubjectChange(value){
      this.paramsData.course_cate_id = value;
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>